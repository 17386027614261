import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewRepresentative extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Representative/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllRepresentatives extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Representative/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteARepresentative extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Representative/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateARepresentative extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Representative/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  CreateNewRepresentative,
  GetAllRepresentatives,
  DeleteARepresentative,
  UpdateARepresentative,
};
