<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>افزودن نماینده</h1>
      </div>
      <b-row>
        <b-col cols="12" md="6">
          <b-form @submit.prevent>
            <b-form-group label="نام نمایندگی" label-for="name">
              <b-form-input
                id="name"
                trim
                placeholder="نام نمایندگی را وارد کنید"
                v-model="representativeDetails.name"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <b-form @submit.prevent>
            <b-form-group label="نام صاحب نمایندگی" label-for="ownerName">
              <b-form-input
                id="ownerName"
                trim
                placeholder="نام صاحب نمایندگی را وارد کنید"
                v-model="representativeDetails.ownerName"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <b-form @submit.prevent>
            <b-form-group
              label="شماره تلفن نمایندگی (با - جدا کنید)"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                trim
                placeholder="شماره تلفن نمایندگی را وارد کنید"
                v-model="representativeDetails.phone"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-for="state" label="محل نمایندگی">
            <b-form-select
              id="state"
              v-model="representativeDetails.state"
              :options="stateOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="آدرس نمایندگی" label-for="address">
            <b-form-textarea
              id="address"
              v-model="representativeDetails.address"
              rows="2"
              placeholder="آدرس نمایندگی را وارد کنید"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="createNewRepresentativeRequest">
          افزودن
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "افزودن نماینده",
  data() {
    return {
      isLoading: false,
      representativeDetails: {
        isDeleted: false,
        representativeId: 0,
        name: null,
        ownerName: null,
        address: null,
        phone: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        // 1 is for tehran
        // 2 is for others
        state: null,
        createDate: new Date(Date.now()).toISOString(),
      },
      stateOptions: [
        {
          value: null,
          text: "یک گزینه را انتخاب کنید",
        },
        {
          value: 1,
          text: "تهران",
        },
        {
          value: 2,
          text: "دیگر استان ها",
        },
      ],
    };
  },
  methods: {
    async createNewRepresentativeRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewRepresentative = new CreateNewRepresentative(_this);
        createNewRepresentative.setRequestParamDataObj(
          _this.representativeDetails
        );
        await createNewRepresentative.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `نماینده با موفقیت ایجاد شد.`,
              },
            });
            _this.representativeDetails = {
              isDeleted: false,
              representativeId: 0,
              name: null,
              ownerName: null,
              address: null,
              phone: null,
              seourl: null,
              seoTitle: null,
              seoDescription: null,
              // 1 is for tehran
              // 2 is for others
              state: null,
              createDate: new Date(Date.now()).toISOString(),
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import { CreateNewRepresentative } from "@/libs/Api/Representative";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
</script>
